<template lang="pug">
button.music-category(
  :class="{ 'is-not-featured': textOnly }"
  :style="setImageHeight"
  type="button"
  @click="chooseMusicCategory"
)
  .music-category__image(
    v-show="image !== null && !textOnly"
  )
    img(
      ref="image"
      :src="image"
    )
  p.music-category__name(:class="{ 'is-image': !textOnly }") {{ name }}
  span.category-name__overlay(v-if="!textOnly")
</template>

<script>
import { mapMutations } from 'vuex';
import { debounce } from 'debounce';

export default {
  name: '',
  props: {
    name: { type: String, default: '' },
    image: { type: String, default: 'http://placehold.it/300x300' },
    id: { type: Number, default: null },
    textOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      imageSize: {
        width: 157,
        height: 200,
      },
    };
  },
  computed: {
    setImageHeight() {
      const styleObject = {};
      if (!this.textOnly) {
        const imageWidth = this.imageSize.width;
        styleObject.height = `${imageWidth + 40}px`;
      }
      return styleObject;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    const img = this.$refs.image;
    const width = img && img.width;
    const height = img && img.height;

    if (width !== 0 && height !== 0) {
      this.imageSize = {
        width,
        height,
      };
    }
  },
  methods: {
    ...mapMutations(['setShowMusicCategories']),
    ...mapMutations('assetsSidebar', [
      'setActiveCategory',
      'clearActiveKeyword',
    ]),
    chooseMusicCategory() {
      this.clearActiveKeyword();
      this.setActiveCategory({ id: this.id, slug: this.name, type: 'category' });
      this.setShowMusicCategories(false);
    },
    // eslint-disable-next-line func-names
    handleResize: debounce(function () {
      this.updateImageSize();
    }),
    updateImageSize() {
      const img = this.$refs.image;
      const width = img && img.width;
      const height = img && img.height;

      if (width && width !== 0) {
        this.imageSize = {
          width,
          height,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.music-category {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  padding: 0;
  height: 270px !important;
  outline: none;

  @include smallest() {
    height: 200px !important;
  }

  &.is-not-featured {
    height: 35px !important;
    box-shadow: none;
    max-width: calc(100% / 4);
    margin-bottom: 0;

    @include smallest() {
      height: 35px !important;
    }

    .music-category__name {
      position: relative;
      bottom: unset;
      left: unset;

      &:hover {
        color: var(--blue700-green700);
      }
    }
  }
}

.music-category__image {
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.music-category__name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 12px 0 15px;
  font-size: 15px;
  font-weight: 400;
  text-align: initial;
  text-transform: capitalize;
  color: var(--darkgrey400-lightgrey500);
  z-index: 3;

  &.is-image {
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: $lightGrey500;
  }
}

.category-name__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(360deg, #000000 0.06%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

</style>

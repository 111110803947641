<template lang="pug">
.music-categories
  CanvasModal(
    icon="icon-music"
    title="Music Category"
    :is-show="isShow"
    :is-grey="true"
    @closeOverlay="closeOverlay"
  )
    .music-categories__tabs
      BaseButton.tab__item(
        v-for="tab in musicCategoriesTab"
        :key="tab.value"
        :class="{ 'is-active': activeCategoryTab === tab.value }"
        :is-text="true"
        :plain-hover="true"
        @click="changeCategoryTab(tab.value)"
      ) {{ tab.name }}

    .music-categories__container
      VueSlickCarousel(
        ref="musicCategoriesCarousel"
        :key="activeCategoryTab"
        v-bind="categoryOptions"
      )
        .music-category__item(v-for="category in featuredMusicCategories(activeCategoryTab)")
          MusicCategory(
            :id="category.id"
            :name="category.name"
            :image="category.background_image"
          )

      .music-categories__list
        template(v-for="category in activeGroupCategories")
          MusicCategory(
            :id="category.id"
            :name="category.name"
            :image="category.background_image"
            :text-only="true"
          )
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import MusicCategory from '@/components/ProjectCanvas/PageOverlay/children/MusicCategory.vue';
import slickMixin from '@/components/mixins/slick-mixins';

export default {
  name: '',
  components: {
    MusicCategory,
  },
  mixins: [slickMixin],
  data() {
    return {
      isShow: false,
      musicCategoriesTab: [
        { name: 'Moods', value: 2 },
        { name: 'Genres', value: 1 },
      ],
      activeCategoryTab: 2,
      categoryOptions: {
        dots: false,
        draggable: false,
        infinite: true,
        speed: 500,
        slidesPerRow: 6,
        slidesToScroll: 1,
      },
    };
  },
  computed: {
    ...mapState(['showMusicCategories']),
    ...mapState('assetsSidebar', ['musicCategories']),
    ...mapGetters('assetsSidebar', ['getMusicCategories', 'getFeaturedMusicCategories']),
    activeGroupCategories() {
      const categories = this.musicCategories.byId[this.activeCategoryTab];
      return categories.children;
    },
  },
  methods: {
    ...mapMutations(['setShowMusicCategories']),
    closeOverlay() {
      this.setShowMusicCategories(false);
    },
    featuredMusicCategories(parentId) {
      return this.getFeaturedMusicCategories.filter(cat => cat.root_parent_id === parentId).sort((a, b) => a.order - b.order);
    },
    changeCategoryTab(value) {
      this.activeCategoryTab = value;
    },
  },
  mounted() {
    if (this.showMusicCategories) this.isShow = true;
  },
  watch: {
    showMusicCategories(val) {
      this.isShow = val;
    },
  },
};
</script>

<style lang="scss">
.music-categories {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  .canvas-modal__content {
    height: 800px;
    width: 80vw;
    max-width: none;
    background: var(--canvasbg-darkgrey700);
  }

  .canvas-modal__header {
    padding: 40px 0 0 50px;
    color: var(--black-white) !important;
    background: var(--canvasbg-darkgrey700) !important;

    .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 19px;
    }

    .icon-music {
      font-size: 22px;
      color: var(--blue700-white) !important;
    }
  }

  .canvas-modal__closebtn {
    color: var(--black-white) !important;
  }

  .content {
    padding: 20px 0;
    overflow: hidden;
  }

  .slick-track {
    padding-bottom: 0 !important;
  }

  .slick-arrow {
    position: absolute;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    color: var(--black-white);
    z-index: 2;
    top: 50%;
    text-indent: 10000px;
    overflow: hidden;
    transition: background 0.25s ease-in-out;

    &.slick-disabled {
      display: none !important;
    }

    &::after {
      content: '';
      font-family: 'icomoon';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-indent: 0;
      font-size: 24px;
      height: 30px;
    }
  }

  .slick-prev {
    left: -40px;
    transform: translateY(-50%);

    &::after {
      content: $icon-left-arrow;
    }
  }

  .slick-next {
    right: -40px;
    transform: translateY(-50%);

    &::after {
      content: $icon-right-arrow;
    }
  }
}

.music-categories__tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  font-size: 20px;
  user-select: none;
  background: none;
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 0 50px;

  .tab__item {
    width: 60px;
    font-size: 17px;
    color: var(--black-white) !important;
    opacity: 0.4;
    background: none;
    padding: 0 0 2px 0;
    border-radius: 0;
    text-align: center;

    &:hover {
      opacity: 1;
    }

    &:hover,

    &:focus,
    &:active {
      background: none;
    }

    &.is-active {
      opacity: 1;
      cursor: context-menu;
      border-bottom: 2px solid var(--blue700-green700);
      background: none;
    }

    &:nth-child(1) {
      margin-right: 15px;
    }

    &:nth-child(2) {
      margin-left: 15px;
    }
  }
}

.music-categories__container {
  height: 100%;
  overflow: auto;
  padding: 0 45px;

  .music-category__item {
    padding: 5px;
    outline: none;
    height: 200px;
  }
}

.music-categories__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  padding: 0 15px;
}
</style>
